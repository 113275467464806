import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsFormValidation } from '../../models/form/bs-form-validation.model';
import { BsFormModel } from '../../models/form/bs-form.model';
import { AppComponentBase } from '../base/app-component-base';

@Component({
    selector: 'bs-form',
    templateUrl: 'bs-form.component.html'
})

export class BsFormComponent extends AppComponentBase implements OnChanges {
    @Input() model: BsFormModel;
    bsFormGroup:FormGroup;

    constructor(injector:Injector) {
        super(injector);
    }

    /**
     * Build Form
     */
    buildForm() {
        let group={}    
        this.model.elements.forEach(element=>{
            group[element.name] = new FormControl('', this.setValidators(element.validators));  
        });

        this.bsFormGroup = new FormGroup(group);
    }

    /**
     * Method for set form element validators
     * @param validators 
     */
    setValidators(validators: BsFormValidation[]):ValidatorFn[] {
        let validator: ValidatorFn[] = [];
        validators.forEach(elem => {
            switch (elem.type) {
                case "required":
                    validator.push(Validators.required);
                break;
                case "maxLength":
                    validator.push(Validators.maxLength(elem.extra));
                break;
                case "minLength":
                    validator.push(Validators.minLength(elem.extra));
                break;
                case "pattern":
                    validator.push(Validators.pattern(elem.extra));
                break;
                case "max":
                    validator.push(Validators.max(elem.extra));
                break;
                case "min":
                    validator.push(Validators.min(elem.extra));
                break;
                default:
                break;
            }
        });
        return validator;
    }

    /**
     * On submit form
     */
    onSubmit() {

    }

    /**
     * On changes lifeclye method
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.buildForm();
    }
}