<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-switch [(ngModel)]="value" [nzSize]="size" [nzLoading]="loading" [nzDisabled]="disabled"
            [nzCheckedChildren]="(checkedChildrenIcon ? checkedTemplate : checkedChildren)"
            [nzUnCheckedChildren]="(unCheckedChildrenIcon ? unCheckedTemplate : unCheckedChildren)">
        </nz-switch>
        <ng-template #checkedTemplate><i nz-icon [nzType]="checkedChildrenIcon"></i></ng-template>
        <ng-template #unCheckedTemplate><i nz-icon [nzType]="unCheckedChildrenIcon"></i></ng-template>
    </nz-form-control>
</nz-form-item>