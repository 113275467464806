<nz-form-item>
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-radio-group [(ngModel)]="value">
            <label *ngFor="let item of items" [nzDisabled]="disabled" nz-radio
                [nzValue]="item.key">{{item.value}}</label>
        </nz-radio-group>
    </nz-form-control>
</nz-form-item>