import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LocalizationService } from 'abp-ng2-module';
import { AppConsts } from 'src/app/shared/AppConsts';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(private localization: LocalizationService) { }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        return component.canDeactivate() ?
            true :
            confirm(this.localization.localize('WarningUnsavedChanges', AppConsts.localization.defaultLocalizationSourceName));
    }
}