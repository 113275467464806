<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-slider *ngIf="!withInput || range" [(ngModel)]="value" [nzRange]="range" [nzDisabled]="disabled"
            [nzMin]="min" [nzMax]="max" [nzStep]="step" [nzReverse]="reverse" [nzTooltipVisible]="tooltipVisible"
            [nzTooltipPlacement]="tooltipPlacement"></nz-slider>
        <nz-row *ngIf="withInput && !range">
            <nz-col nzSpan="20">
                <nz-slider [(ngModel)]="value" [nzRange]="range" [nzDisabled]="disabled" [nzMin]="min" [nzMax]="max"
                    [nzStep]="step" [nzReverse]="reverse" [nzTooltipVisible]="tooltipVisible"
                    [nzTooltipPlacement]="tooltipPlacement"></nz-slider>
            </nz-col>
            <div nz-col nzSpan="4">
                <nz-input-number [nzMin]="min" [nzMax]="max" [nzStep]="step" [ngStyle]="{ marginLeft: '16px' }"
                    [(ngModel)]="value">
                </nz-input-number>
            </div>
        </nz-row>

    </nz-form-control>
</nz-form-item>