import { Component, forwardRef, Optional, Host, SkipSelf, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormElement } from '../base/base-form-element';

@Component({
    selector: 'bs-slider',
    templateUrl: 'bs-slider.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsSliderComponent),
            multi: true
        }
    ]
})
export class BsSliderComponent extends BaseFormElement {
    @Input() range: boolean = false;
    @Input() min: number = 0;
    @Input() max: number = 100;
    @Input() step: number = 1;
    @Input() reverse: boolean = false;
    @Input() tooltipVisible: string = 'default';
    @Input() tooltipPlacement: string = 'top';
    @Input() withInput: boolean = false;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }
}