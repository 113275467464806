import { BaseFormElement } from '../base/base-form-element';
import { Input, Optional, Host, SkipSelf, forwardRef, Component, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import * as moment from 'moment';
import { AppConsts } from 'src/app/shared/AppConsts';

@Component({
    selector: 'bs-range-picker',
    templateUrl: 'bs-range-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsRangePickerComponent),
            multi: true
        }
    ]
})

export class BsRangePickerComponent extends BaseFormElement {
    @Input() format: string = AppConsts.regional.filter(it => it.lng == abp.localization.currentLanguage.name)[0].value.dateFormat;
    @Input() disabledDate: any;
    @Input() allowClear: boolean = true;
    @Input() showTime: boolean = false;
    @Input() separator: string = '~';
    @Input() placeholders: string[] = [this.l("StartDate"), this.l("EndDate")];
    @Input() disabledTime: any;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //on date change
    onDateChange(result: Date[]): void {
        let range = [moment(result[0]), moment(result[1])];
        this.onChange(range);
        this.onTouch(range);
    }

    //method to set the value to the native form control
    writeValue(value: moment.Moment[]): void {
        if (value != null)
            this.value = [value[0].toDate(), value[1].toDate()];
    }
}