import {Injectable} from '@angular/core';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { SensorServiceProxy } from '../http/service-proxies/service-proxies';
import { saveAs } from 'file-saver';
//service for sharing data between components
@Injectable()
export class SharedService {
    //just for example
    private value = new BehaviorSubject("shared value");

    observableValue = this.value.asObservable();

    //method for setting value
    setValue(value: string) {
        this.value.next(value);
    }

    downloadFile(data, name): any {
        let sliceSize = 512;

        var byteCharacters = atob(data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: "text/csv"});
        saveAs(blob, name);

    }
}