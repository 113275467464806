export class BsTableHeaderAction {
    /**
     * Unique header action name
     *
     * @type {string}
     * @memberof BsTableHeaderAction
     */
    name: string;

    /**
     * Icon of action button
     *
     * @type {string}
     * @memberof BsTableHeaderAction
     */
    icon: string;

    /**
     * Button type: 'default' | 'primary' | 'danger'
     *
     * @type {string}
     * @memberof BsTableHeaderAction
     */
    type: string = 'primary'

    /**
     * Action text
     *
     * @type {string}
     * @memberof BsTableHeaderAction
     */
    text: string;

    /**
     * Action is enabled when one or more items in data table is selected
     *
     * @type {boolean}
     * @memberof BsTableHeaderAction
     */
    enabledIfSelectedItems: boolean = false;

    /**
     * Confirm title (if null there is no confirm box)
     *
     * @type {string}
     * @memberof BsTableAction
     */
    confirmTitle: string = null;

    /**
     * Confirm placement
     *
     * @type {string}
     * @memberof BsTableAction
     */
    confirmPlacement: string = 'bottom';

    
    /**
     * Disabled
     *
     * @type {boolean}
     * @memberof BsTableHeaderActionEmit
     */
    disabled: boolean = false;

    public constructor(init?: Partial<BsTableHeaderAction>) {
        Object.assign(this, init);
    }


}

export class BsTableHeaderActionEmit {
    action: string;
    selectedItems: Set<number>;
    disabled: boolean;

    public constructor(init?: Partial<BsTableHeaderActionEmit>) {
        Object.assign(this, init);
    }
}
