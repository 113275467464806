import { Component, OnInit, forwardRef, Optional, Host, SkipSelf, ChangeDetectorRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormElement } from '../base/base-form-element';

@Component({
    selector: 'bs-checkbox',
    templateUrl: 'bs-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsCheckboxComponent),
            multi: true
        }
    ]
})

export class BsCheckboxComponent extends BaseFormElement {
    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //validate component
    validate() { }
}