<div class="content-box" *ngIf="loading">
  <nz-skeleton
    [nzLoading]="loading"
    [nzParagraph]="{ rows: 12 }"
    [nzActive]="true"
  ></nz-skeleton>
</div>

<ng-container *ngIf="!loading">
  <div class="table-content-box" *ngIf="model.headerActions.length > 0">
    <div class="action-panel">
      <ng-container *ngFor="let action of model.headerActions">
        <button
          class="header-action-btn"
          *ngIf="action.confirmTitle == null"
          nz-button
          [nzType]="action.type"
          [disabled]="
            (action.enabledIfSelectedItems && setOfCheckedId.size == 0) ||
            action.disabled
          "
          (click)="emitHeaderAction(action)"
        >
          <i *ngIf="action.icon != null" nz-icon [nzType]="action.icon"></i>
          {{ action.text }}
          <ng-container
            *ngIf="action.enabledIfSelectedItems && setOfCheckedId.size > 0"
          >
            ({{ setOfCheckedId.size }})
          </ng-container>
        </button>
        <button
          class="header-action-btn"
          *ngIf="action.confirmTitle != null"
          nz-button
          [nzType]="action.type"
          [disabled]="
            (action.enabledIfSelectedItems && setOfCheckedId.size == 0) ||
            action.disabled
          "
          (nzOnConfirm)="emitHeaderAction(action)"
          nz-popconfirm
          [nzPopconfirmPlacement]="action.confirmPlacement"
          [nzPopconfirmTitle]="action.confirmTitle"
        >
          <i *ngIf="action.icon != null" nz-icon [nzType]="action.icon"></i>
          {{ action.text }}
          <ng-container
            *ngIf="action.enabledIfSelectedItems && setOfCheckedId.size > 0"
          >
            ({{ setOfCheckedId.size }})
          </ng-container>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="table-content-box">
    <div class="search-box" *ngIf="model.filters.length > 0">
      <ng-container *ngIf="!model.options.collapseFilter">
        <div nz-row [nzGutter]="[12, 12]">
          <ng-container *ngFor="let filter of model.filters">
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'string' || filter.type == null"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <input
                  type="text"
                  nz-input
                  [placeholder]="filter.placeholder"
                  (keyup.enter)="search()"
                  [(ngModel)]="filterData[filter.name]"
                />
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'boolean'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <label
                  nz-checkbox
                  [(ngModel)]="filterData[filter.name]"
                ></label>
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'range'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-range-picker
                  class="range-picker"
                  [(ngModel)]="filterData[filter.name]"
                  (ngModelChange)="rangeChanged($event, filter)"
                  nzFormat="dd.MM.yyyy"
                  nzAllowClear
                >
                </nz-range-picker>
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'rangedatetime'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-range-picker
                  class="range-picker"
                  [nzShowTime]="true"
                  nzSize="small"
                  [(ngModel)]="filterData[filter.name]"
                  nzFormat="dd.MM.yyyy HH:mm:ss"
                  nzAllowClear
                >
                </nz-range-picker>
              </nz-form-control>
            </div>

            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'number'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-input-number
                  nzPlaceHolder="{{ filter.placeholder }}"
                  [(ngModel)]="filterData[filter.name]"
                  [nzStep]="1"
                  (keyup.enter)="search()"
                >
                </nz-input-number>
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'select'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-select
                  (keyup.enter)="search()"
                  [nzShowSearch]="true"
                  nzAllowClear
                  (ngModelChange)="onSelectChnage($event, filter)"
                  nzPlaceHolder="{{ filter.placeholder }}"
                  [(ngModel)]="filterData[filter.name]"
                >
                  <nz-option
                    *ngFor="let item of filter.selectValues"
                    [nzValue]="item.value"
                    [nzLabel]="item.label"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'multiple-select'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-select
                  (keyup.enter)="search()"
                  [nzShowSearch]="true"
                  nzAllowClear
                  nzPlaceHolder="{{ filter.placeholder }}"
                  [(ngModel)]="filterData[filter.name]"
                  nzMode="multiple"
                >
                  <nz-option
                    *ngFor="let item of filter.selectValues"
                    [nzValue]="item.value"
                    [nzLabel]="item.label"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </div>
            <div
              nz-col
              nzXs="24"
              [nzLg]="filter.width"
              *ngIf="filter.type == 'select-three-state'"
            >
              <nz-form-label>{{ filter.label }}</nz-form-label>
              <nz-form-control>
                <nz-select
                  (keyup.enter)="search()"
                  [nzShowSearch]="true"
                  nzPlaceHolder="{{ filter.placeholder }}"
                  [(ngModel)]="filterData[filter.name]"
                >
                  <nz-option
                    *ngFor="let item of selectThreeStateItems"
                    [nzValue]="item.value"
                    [nzLabel]="item.label"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </ng-container>
        </div>
        <div nz-row [nzGutter]="[24, 12]">
          <div
            nz-col
            nzXs="24"
            nzSm="24"
            nzMd="24"
            nzLg="4"
            nzXl="3"
            nzXXl="2"
            class="btn-box"
          >
            <button nz-button [nzType]="'primary'" (click)="search()">
              <i nz-icon nzType="search" nzTheme="outline"></i
              >{{ model.options.filterSearchText }}
            </button>
          </div>
          <div
            nz-col
            nzXs="24"
            nzSm="24"
            nzMd="24"
            nzLg="4"
            nzXl="3"
            nzXXl="2"
            class="btn-box"
          >
            <button nz-button [nzType]="'default'" (click)="reset()">
              <i nz-icon nzType="rollback" nzTheme="outline"></i
              >{{ model.options.filterResetText }}
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf="model.options.collapseFilter">&nbsp;</div>
      <div
        class="collapse"
        nz-tooltip
        [nzTooltipTitle]="
          model.options.collapseFilter ? ('ShowFilter' | l) : ('HideFilter' | l)
        "
        (click)="model.options.collapseFilter = !model.options.collapseFilter"
      >
        <a>
          <i
            nz-icon
            [nzType]="model.options.collapseFilter ? 'down' : 'up'"
            nzTheme="outline"
          ></i>
        </a>
      </div>
    </div>
  </div>

  <div class="table-content-box" *ngIf="showTable">
    <nz-table
      #dataTable
      [nzTableLayout]="model.options.tableLayout"
      [nzData]="displayedData"
      [nzLoading]="serverLoading"
      [nzPageSize]="model.options.pageSize"
      [nzPageIndex]="model.options.pageIndex"
      [nzShowPagination]="model.options.showPagination"
      [nzBordered]="model.options.bordered"
      [nzPaginationPosition]="model.options.paginationPosition"
      [nzSize]="model.options.size"
      [nzTitle]="model.options.title"
      [nzFooter]="model.options.footer"
      [nzNoResult]="model.options.noResult"
      [nzPageSizeOptions]="model.options.pageSizeOptions"
      [nzShowQuickJumper]="model.options.showQuickJumper"
      [nzShowSizeChanger]="model.options.showSizeChanger"
      [nzShowTotal]="totalTemplate"
      [nzHideOnSinglePage]="model.options.hideOnSinglePage"
      [nzSimple]="model.options.simple"
      [nzTotal]="total"
      [nzFrontPagination]="model.options.frontPagination"
      [nzScroll]="model.options.scroll"
      (nzPageIndexChange)="pageChanged($event)"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th *ngIf="model.options.expand" nzWidth="60px">&nbsp;</th>
          <th
            *ngIf="model.options.selection"
            [nzChecked]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th
            *ngFor="let column of model.columns | filter : 'expand' : false"
            [nzSortFn]="column.sortFn"
            [nzSortOrder]="column.sortOrder"
            [nzSortDirections]="column.sortDirections"
            [nzWidth]="column.width"
            [nzLeft]="column.left"
            [nzRight]="column.right"
            [nzAlign]="column.align"
            [nzColumnKey]="column.name"
            [nzBreakWord]="column.breakWord"
            [nzLeft]="column.fixLeft"
            [nzRight]="column.fixRight"
            [ngClass]="addClassToColumn(column)"
          >
            {{ column.displayName }}
          </th>
          <th
            [nzWidth]="model.options.actionColumnWidth"
            *ngIf="model.actions.length > 0"
            nzAlign="right"
          >
            {{ model.options.actionColumnHeader }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of dataTable.data">
          <tr>
            <td
              *ngIf="model.options.expand"
              [nzExpand]="expandSet.has(data.id)"
              (nzExpandChange)="onExpandChange(data.id, $event)"
            ></td>
            <td
              *ngIf="model.options.selection"
              [nzChecked]="setOfCheckedId.has(data.id)"
              (nzCheckedChange)="onItemChecked(data.id, $event)"
            ></td>
            <td
              *ngFor="let column of model.columns | filter : 'expand' : false"
              [innerHTML]="
                column.localize
                  ? l(data[column.name])
                  : formatData(
                      data[column.name],
                      column.type,
                      column.suffixText,
                      column.prefixText
                    )
              "
              [nzLeft]="column.left"
              [nzRight]="column.right"
              [nzAlign]="column.align"
              [nzBreakWord]="column.breakWord"
              [nzLeft]="column.fixLeft"
              [nzRight]="column.fixRight"
              [ngClass]="addClassToColumn(column)"
              [ngStyle]="rowHighlight(data, model.options.rowHighlight)"
              (click)="model.options.onRowClick && emitRowClick(data)"
            ></td>
            <td
              nzAlign="right"
              *ngIf="model.actions.length > 0"
              [ngStyle]="rowHighlight(data, model.options.rowHighlight)"
            >
              <ng-container *ngIf="model.options.actionsDesign === 'buttons'">
                <ng-container *ngFor="let action of model.actions">
                  <ng-container *ngIf="!hideAction(data, action.hide)">
                    <button
                      class="action-btn"
                      *ngIf="action.confirmTitle != null"
                      nz-button
                      [nzType]="action.type"
                      nzSize="small"
                      nz-popover
                      [nzPopoverContent]="action.popoverContent"
                      [nzPopoverPlacement]="action.popoverPlacement"
                      (nzOnConfirm)="emitAction(action.name, data)"
                      nz-popconfirm
                      [nzPopconfirmPlacement]="action.confirmPlacement"
                      [nzPopconfirmTitle]="action.confirmContent"
                      [disabled]="disableAction(data, action.disable)"
                    >
                      <i nz-icon [nzType]="action.icon" nzTheme="outline"></i>
                    </button>
                    <button
                      class="action-btn"
                      *ngIf="action.confirmTitle == null"
                      nz-button
                      [nzType]="action.type"
                      nzSize="small"
                      nz-popover
                      [nzPopoverContent]="action.popoverContent"
                      (click)="emitAction(action.name, data)"
                      [nzPopoverPlacement]="action.popoverPlacement"
                    >
                      <i nz-icon [nzType]="action.icon" nzTheme="outline"></i>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="model.options.actionsDesign === 'dropdown'">
                <a nz-dropdown [nzDropdownMenu]="menu">
                  <i nz-icon nzType="menu" nzTheme="outline"></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <ng-container *ngFor="let action of model.actions">
                      <li
                        nz-menu-item
                        *ngIf="!hideAction(data, action.hide)"
                        (click)="
                          !disableAction(data, action.disable) &&
                            emitAction(
                              action.name,
                              data,
                              action.confirmTitle,
                              action.confirmContent
                            )
                        "
                        [nzDisabled]="disableAction(data, action.disable)"
                      >
                        <i nz-icon [nzType]="action.icon" nzTheme="outline"></i>
                        {{ action.popoverContent }}
                      </li>
                    </ng-container>
                  </ul>
                </nz-dropdown-menu>
              </ng-container>
            </td>
          </tr>
          <ng-container
            *ngFor="let column of model.columns | filter : 'expand' : true"
          >
            <tr [nzExpand]="expandSet.has(data.id)">
              <span>
                <strong>{{ column.displayName }}</strong
                >:
                <span
                  [innerHTML]="
                    formatData(
                      data[column.name],
                      column.type,
                      column.suffixText,
                      column.prefixText
                    )
                  "
                ></span>
              </span>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
  <ng-template #totalTemplate let-total>
    {{ "TotalXItems" | l : total }}</ng-template
  >
</ng-container>
