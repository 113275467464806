import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { Component, forwardRef, Optional, Host, SkipSelf, Input, Injector } from '@angular/core';
import { BaseFormElement } from '../base/base-form-element';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
    selector: 'bs-file-upload',
    templateUrl: 'bs-file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsFileUploadComponent),
            multi: true
        }
    ]
})

export class BsFileUploadComponent extends BaseFormElement {
    @Input() accept: string;
    @Input() multiple: boolean = false;
    @Input() limit: number = 0;
    @Input() fileSize: number = 0;
    @Input() fileType: string;
    @Input() listType: string = 'text';
    @Input() name: string = 'file';
    @Input() showUploadList: any = true;

    fileList: NzUploadFile[] = [];

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector) {
        super(controlContainer, injector);
    }

    //remove file event
    remove = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.filter(it => it.uid != file.uid);
        this.onChange(this.fileList);
        return true;
    }

    //get base64 of file
    private getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', (e) =>  {
            callback(reader.result!.toString())
        });
        reader.readAsDataURL(img);
    }


    //before upload event
    beforeUpload = (file: NzUploadFile): boolean => {
        this.getBase64(file as unknown as File, (base64: string) => {
            file["base64"] = base64;
            this.fileList = this.fileList.concat(file);
            this.onChange(this.fileList);
        });

        return false;
    };
}