import { Component } from '@angular/core';
import { NzI18nService, en_GB, de_DE } from 'ng-zorro-antd/i18n';
import { de, enGB } from 'date-fns/locale';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private i18n: NzI18nService) {
    if (abp.localization.currentLanguage.name === "de") {
      this.i18n.setLocale(de_DE);
      this.i18n.setDateLocale(de)
    } else {
      this.i18n.setLocale(en_GB);
      this.i18n.setDateLocale(enGB)
    }
  }
}
