export class BsTableAction {
    /**
     * Unique action name
     *
     * @type {string}
     * @memberof BsTableAction
     */
    name: string;

    /**
     * Icon of action button
     *
     * @type {string}
     * @memberof BsTableAction
     */
    icon: string;

    /**
     * Button type: 'default' | 'primary' | 'danger'
     *
     * @type {string}
     * @memberof BsTableAction
     */
    type: string = 'default'

    /**
     * Popover content
     *
     * @type {string}
     * @memberof BsTableAction
     */
    popoverContent: string;

    /**
     * Popover placement
     *
     * @type {string}
     * @memberof BsTableAction
     */
    popoverPlacement: string = 'right';

    /**
     * Confirm title (if null there is no confirm box)
     *
     * @type {string}
     * @memberof BsTableAction
     */
    confirmTitle: string = null;

    /**
     * Confirm title (if null there is no confirm box)
     *
     * @type {string}
     * @memberof BsTableAction
     */
    confirmContent: string = null;

    /**
     * Confirm placement
     *
     * @type {string}
     * @memberof BsTableAction
     */
    confirmPlacement: string = 'bottom';

    /**
     * Disable action base on data in table
       For example - disable action if user is active and his name is 'Marko':
          {
            logical:"AND"
            conditions: [
                { prop: 'isActive', operator: '===', value: true },
                { prop: 'name', operator: '===', value: 'Marko' }
            ]
        }
     * logical: can be 'AND' or 'OR' (relation between multiple conditions) - if not set than it is 'OR'
     * conditions: array of conditions: prop - name of property in dataset, operator: javascript comparison operator, value: value to check
     *
     * @type {*}
     * @memberof BsTableAction
     */
    disable: any = null;

    /**
     *Creates an instance of BsTableAction.
     * @param {Partial<BsTableAction>} [init]
     * @memberof BsTableAction
     */
    public constructor(init?: Partial<BsTableAction>) {
        Object.assign(this, init);
    }
}

export class BsTableActionEmit {

    /**
     * Action name
     *
     * @type {string}
     * @memberof BsTableActionEmit
     */
    action: string;


    /**
     * Seleted item in data table
     *
     * @type {*}
     * @memberof BsTableActionEmit
     */
    item: any;

    /**
     *Creates an instance of BsTableActionEmit.
     * @param {Partial<BsTableActionEmit>} [init]
     * @memberof BsTableActionEmit
     */
    public constructor(init?: Partial<BsTableActionEmit>) {
        Object.assign(this, init);
    }
}