import { ControlValueAccessor, ControlContainer, AbstractControl } from '@angular/forms';
import { Optional, Host, SkipSelf, Input, OnInit, Injector, Directive } from '@angular/core';
import { AppComponentBase } from '../../base/app-component-base';

@Directive()
export class BaseFormElement extends AppComponentBase implements ControlValueAccessor, OnInit {

    @Input() formControlName: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() info: string;
    @Input() size: string = 'default';

    @Input() requiredErrorMessage: string = this.l("ErrorFieldRequired");

    hasErrors: boolean;
    errorRequired: boolean;

    public ctrl: AbstractControl;
    disabled: boolean;
    onTouch: any = () => { };
    onChange: any = () => { }

    private _value: any = null;

    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(injector);
    }

    //set value
    set value(val) {
        if (val !== undefined && this._value !== val) {
            this._value = val;
            this.onChange(val);
            this.onTouch(val);
            this.validate();
        }
    }

    //get value
    get value() {
        return this._value;
    }

    //check if required
    get isRequired() {
        if (this.ctrl.validator) {
            const validator = this.ctrl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    //method to register a callback that is expected to be triggered every time the native form control is updated
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    //method to indicate that a user interacted with a control
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    //set disable state
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    //method to set the value to the native form control
    writeValue(value: any): void {
        //if (value != null) {
        this.value = value;
        //}
    }

    //validate method: implemented in derived method
    validate() {
        this.errorRequired = this.ctrl.touched && this.ctrl.hasError('required') ? true : false;

        if (this.errorRequired) {
            this.hasErrors = true;
        } else {
            this.hasErrors = false;
        }
    }

    //on init lifecycle method
    ngOnInit() {
        if (this.controlContainer) {
            if (this.formControlName) {
                this.ctrl = this.controlContainer.control.get(this.formControlName);
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }
}