<nz-form-item>
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" [nzRemove]="remove" [nzAccept]="accept"
            [nzDisabled]="disabled" [nzMultiple]="multiple" [nzLimit]="limit" [nzSize]="fileSize"
            [nzFileType]="fileType" [nzListType]="listType" [nzName]="name" [nzShowUploadList]="showUploadList">
            <button type="button" [nzSize]="size" nz-button><i nz-icon nzType="upload"></i>Select File</button>
        </nz-upload>
    </nz-form-control>
</nz-form-item>