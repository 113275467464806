import { Component, forwardRef, Input, Optional, Host, SkipSelf, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormElement } from '../base/base-form-element';

@Component({
    selector: 'bs-switch',
    templateUrl: 'bs-switch.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsSwitchComponent),
            multi: true
        }
    ]
})
export class BsSwitchComponent extends BaseFormElement {
    @Input() checkedChildren: string;
    @Input() unCheckedChildren: string;
    @Input() checkedChildrenIcon: string;
    @Input() unCheckedChildrenIcon: string;
    @Input() loading: boolean = false;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }
}
