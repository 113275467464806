<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-range-picker [(ngModel)]="value" [nzShowTime]="showTime" [nzDisabled]="disabled" [nzSize]="size"
            [nzPlaceHolder]="placeholders" [nzFormat]="format" (ngModelChange)="onDateChange($event)"
            [nzDisabledDate]="disabledDate" [nzAllowClear]="allowClear" [nzSeparator]="separator"
            [nzDisabledTime]="disabledTime">
        </nz-range-picker>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>