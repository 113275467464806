<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <input [placeholder]="placeholder" nz-input [(ngModel)]="value" (input)="onInput($event)"
            [nzAutocomplete]="auto" />
        <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let item of items" [nzValue]="item.value" [nzLabel]="item.label"
                [nzDisabled]="item.disabled">
                {{item.label}}
            </nz-auto-option>
        </nz-autocomplete>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>