import { Component, forwardRef, Optional, Host, SkipSelf, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormElement } from '../base/base-form-element';

@Component({
    selector: 'bs-email',
    templateUrl: 'bs-email.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsEmailComponent),
            multi: true
        }
    ]
})
export class BsEmailComponent extends BaseFormElement {
    @Input() addOnAfter: string = null;
    @Input() addOnBefore: string = null;
    @Input() prefix: string = null;
    @Input() suffix: string = null;
    @Input() emailErrorMessage: string = this.l("ErrorEmail");
    @Input() maxLengthErrorMessage: string = this.l("ErrorMaxLength");
    @Input() minLengthErrorMessage: string = this.l("ErrorMinLength");

    errorMaxLength: boolean;
    errorMinLength: boolean;
    errorEmail: boolean;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //check to show input as group
    get showAsGroup() {
        return this.addOnBefore != null || this.addOnAfter != null || this.prefix != null || this.suffix != null;
    }

    //validate component
    validate() {
        this.errorRequired = this.ctrl.touched && this.ctrl.hasError('required') ? true : false;
        this.errorMaxLength = this.ctrl.touched && this.ctrl.hasError('maxlength') ? true : false;
        this.errorMinLength = this.ctrl.touched && this.ctrl.hasError('minlength') ? true : false;
        this.errorEmail = false;

        if (this.ctrl.touched && this.ctrl.value != null && this.ctrl.value != '' && !this.errorRequired && !this.errorMinLength && !this.errorMaxLength) {
            let regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");
            this.errorEmail = !regexp.test(this.ctrl.value);
        }

        if (this.errorRequired || this.errorEmail || this.errorMaxLength || this.errorMinLength) {
            this.hasErrors = true;
        } else {
            this.hasErrors = false;
        }
    }
}