import { Injectable } from "@angular/core";
import * as FileSaver from 'file-saver';

@Injectable()
export class DocumentService {

    //method for download document
    download(data, name) {
        let sliceSize = 512;

        var byteCharacters = data;
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays);
        FileSaver.saveAs(blob, name);
    }
}
