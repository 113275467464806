<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <textarea *ngIf="rows == 0" nzAutosize nz-input [disabled]="disabled" [placeholder]="placeholder"
            [(ngModel)]="value"></textarea>
        <textarea *ngIf="rows > 0" [rows]="rows" nz-input [disabled]="disabled" [placeholder]="placeholder"
            [(ngModel)]="value"></textarea>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>