import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { BaseFormElement } from '../base/base-form-element';
import { ValueLabelDto } from 'src/app/core/http/service-proxies/service-proxies';

@Component({
    selector: 'bs-autocomplete',
    templateUrl: 'bs-autocomplete.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsAutocompleteComponent),
            multi: true
        }
    ]
})

export class BsAutocompleteComponent extends BaseFormElement {
    @Input() items: ValueLabelDto[] = [];
    @Output() onInputChanged: EventEmitter<string> = new EventEmitter();

    /**
     * On input change event
     *
     * @param {*} event
     * @memberof BsSelectComponent
     */
    onInput(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.onInputChanged.emit(value);
    }
}