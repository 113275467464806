<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <input *ngIf="autocomplete" nz-input type="password" name="{{label}}" autocomplete="on" [disabled]="disabled"
            [nzSize]="size" [(ngModel)]="value" />
        <input *ngIf="!autocomplete" nz-input type="password" name="{{label}}" autocomplete="new-password"
            [disabled]="disabled" [nzSize]="size" [(ngModel)]="value" />
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorMaxLength">{{maxLengthErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorMinLength">{{minLengthErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorPassword">{{passwordErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>