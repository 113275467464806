<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-input-group *ngIf="showAsGroup" [nzAddOnBefore]="addOnBefore" [nzAddOnAfter]="addOnAfter"
            [nzPrefix]="prefix" [nzSuffix]="suffix" [nzSize]="size">
            <input type="text" nz-input [placeholder]="placeholder" [disabled]="disabled" [(ngModel)]="value" />
        </nz-input-group>
        <input *ngIf="!showAsGroup" type="text" nz-input [placeholder]="placeholder" [nzSize]="size"
            [disabled]="disabled" [(ngModel)]="value" />
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorMaxLength">{{maxLengthErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorMinLength">{{minLengthErrorMessage}} </div>
        <div class="ant-form-item-explain" *ngIf="errorPattern">{{patternErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>