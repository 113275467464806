import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './shared/modules/icons-provider.module';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import { AppInitializer } from 'src/app-initalizer';
import { AppConsts } from './shared/AppConsts';
import { SharedModule } from './shared/modules/shared.module';
import { ServiceProxyModule } from './core/http/service-proxies/service-proxies.module';
import { API_BASE_URL } from './core/http/service-proxies/service-proxies';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouteEventsService } from './core/services/router-event.service';
import { ChartsModule } from 'ng2-charts';

export function getCurrentLanguage(): string {
  if (abp.localization.currentLanguage.name) {
    return abp.localization.currentLanguage.name;
  }
  // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
  return 'en';
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    ServiceProxyModule,
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AbpHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
      deps: [AppInitializer],
      multi: true,
    },
    { provide: API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLanguage,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    RouteEventsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
