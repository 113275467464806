<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-select *ngIf="items != null && items.length > 0" [(ngModel)]="value" [nzDisabled]="disabled"
            [nzShowSearch]="showSearch" [nzAllowClear]="allowClear" [nzPlaceHolder]="placeholder" [nzMode]="mode"
            [nzMaxTagCount]="maxTagCount" [nzMaxMultipleCount]="maxMultipleCount" [nzSize]="size" [nzLoading]="loading"
            [nzSuffixIcon]="suffixIcon">
            <nz-option *ngFor="let item of items" [nzValue]="item.value" [nzLabel]="item.label"
                [nzDisabled]="item.disabled"></nz-option>
        </nz-select>
        <nz-select *ngIf="groupItems != null && groupItems.length > 0 && items.length == 0" [(ngModel)]="value"
            [nzDisabled]="disabled" [nzShowSearch]="showSearch" [nzAllowClear]="allowClear"
            [nzPlaceHolder]="placeholder" [nzMode]="mode" [nzMaxTagCount]="maxTagCount"
            [nzMaxMultipleCount]="maxMultipleCount" [nzSize]="size" [nzLoading]="loading" [nzSuffixIcon]="suffixIcon">
            <nz-option-group *ngFor="let groupItem of groupItems" [nzLabel]="groupItem.option">
                <nz-option *ngFor="let item of groupItem.items" [nzValue]="item.value" [nzLabel]="item.label"
                    [nzDisabled]="item.disabled"></nz-option>
            </nz-option-group>
        </nz-select>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>