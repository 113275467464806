<form [nzLayout]="'vertical'" nz-form [formGroup]="bsFormGroup" (ngSubmit)="onSubmit()">
    <div *ngFor="let element of model.elements">
        <div nz-row [nzGutter]="24">
            <ng-container [ngSwitch]="element.inputType">
                <span nz-col nzXs=24 *ngSwitchCase="'textbox'">
                    <bs-textbox [formControlName]="element.name" [label]="element.label"></bs-textbox>
                </span>
            </ng-container>
        </div>
    </div>
</form>