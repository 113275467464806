import { Component, forwardRef, Input, Optional, Host, SkipSelf, OnInit, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, ControlValueAccessor, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { BaseFormElement } from '../base/base-form-element';
import { AppConsts } from 'src/app/shared/AppConsts';

@Component({
    selector: 'bs-date-picker',
    templateUrl: 'bs-date-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsDatePickerComponent),
            multi: true
        }
    ]
})

export class BsDatePickerComponent extends BaseFormElement implements OnChanges {

    @Input() format: string = AppConsts.regional.filter(it => it.lng == abp.localization.currentLanguage.name)[0].value.dateFormat;
    @Input() showTime: boolean = false;
    @Input() disabledDate: any;
    @Input() allowClear: boolean = true;
    @Input() disabledTime: any;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //on date change
    onDateChange(result: Date): void {
        let date = moment(result);
        this.onChange(date);
        this.onTouch(date);
    }

    //method to set the value to the native form control
    writeValue(value: moment.Moment): void {
        if (value != null)
            this.value = value.toDate();
    }

    //on changes lifecyle method
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showTime != null && changes.showTime.currentValue == true) {
            this.format = AppConsts.regional.filter(it => it.lng == abp.localization.currentLanguage.name)[0].value.dateTimeFormat;
        }
    }
}