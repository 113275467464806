import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@NgModule({
    imports: [
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        NzCheckboxModule,
        NzMenuModule,
        NzDropDownModule,
        NzAlertModule,
        NzResultModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzIconModule,
        NzRadioModule,
        NzInputNumberModule,
        NzSelectModule,
        NzDatePickerModule,
        NzTimePickerModule,
        NzCollapseModule,
        NzDividerModule,
        NzUploadModule,
        NzTableModule,
        NzPopconfirmModule,
        NzPopoverModule,
        NzSkeletonModule,
        NzDrawerModule,
        NzModalModule,
        NzAutocompleteModule,
        NzSliderModule,
        NzSwitchModule,
        NzTabsModule
    ],
    exports: [
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        NzCheckboxModule,
        NzMenuModule,
        NzDropDownModule,
        NzAlertModule,
        NzResultModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzIconModule,
        NzRadioModule,
        NzInputNumberModule,
        NzSelectModule,
        NzDatePickerModule,
        NzTimePickerModule,
        NzCollapseModule,
        NzDividerModule,
        NzUploadModule,
        NzTableModule,
        NzPopconfirmModule,
        NzPopoverModule,
        NzSkeletonModule,
        NzDrawerModule,
        NzModalModule,
        NzAutocompleteModule,
        NzSliderModule,
        NzSwitchModule,
        NzTabsModule
    ],
    declarations: [],
    providers: [],
})
export class NgZorroElementsModule { }
