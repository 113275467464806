import { Component, forwardRef, Optional, Host, SkipSelf, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseFormElement } from '../base/base-form-element';

@Component({
    selector: 'bs-password',
    templateUrl: 'bs-password.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsPasswordtComponent),
            multi: true
        }
    ]
})

export class BsPasswordtComponent extends BaseFormElement {
    @Input() pattern: string;
    @Input() passwordErrorMessage: string = this.l("ErrorPassword");
    @Input() maxLengthErrorMessage: string = this.l("ErrorMaxLength");
    @Input() minLengthErrorMessage: string = this.l("ErrorMinLength");
    @Input() autocomplete: boolean = true;

    errorMaxLength: boolean;
    errorMinLength: boolean;
    errorPassword: boolean;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //validate component
    validate() {
        this.errorRequired = this.ctrl.touched && this.ctrl.hasError('required') ? true : false;
        this.errorMaxLength = this.ctrl.touched && this.ctrl.hasError('maxlength') ? true : false;
        this.errorMinLength = this.ctrl.touched && this.ctrl.hasError('minlength') ? true : false;
        this.errorPassword = false;

        if (this.pattern != null && this.pattern != '' && this.ctrl.value != null && this.ctrl.value != ''
            && !this.errorRequired && !this.errorMaxLength && !this.errorMinLength) {
            let regexp = new RegExp(this.pattern);
            this.errorPassword = !regexp.test(this.ctrl.value);
        }

        if (this.errorRequired || this.errorMaxLength || this.errorMinLength || this.errorPassword) {
            this.hasErrors = true;
        } else {
            this.hasErrors = false;
        }
    }
}