import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { Component, forwardRef, Optional, Host, SkipSelf, Input, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFormElement } from '../base/base-form-element';
import { OptionGroupKeyValueModel } from 'src/app/shared/models/base/key-value.model';
import { ValueLabelDto } from 'src/app/core/http/service-proxies/service-proxies';

@Component({
    selector: 'bs-select',
    templateUrl: 'bs-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsSelectComponent),
            multi: true
        }
    ]
})

export class BsSelectComponent extends BaseFormElement implements OnChanges {

    @Input() items: ValueLabelDto[] = [];
    @Input() groupItems: OptionGroupKeyValueModel[] = [];
    @Input() showSearch: boolean = true;
    @Input() allowClear: boolean = true;
    @Input() mode: string = 'default';
    @Input() maxTagCount: number = 3;
    @Input() maxMultipleCount: number = Infinity;
    @Input() loading: boolean = false;
    @Input() suffixIcon: string = '';

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
    }
}