import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from '../pipes/moment.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { SharedService } from 'src/app/core/services/shared.service';
import { AppRouteGuard } from 'src/app/core/guards/auth-route.guard';
import { AppUrlService } from 'src/app/core/services/app-url.service';
import { AppAuthService } from 'src/app/core/services/app-auth.service';
import { AppSessionService } from 'src/app/core/services/app-session.service';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { BusyDirective } from '../directives/busy.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsFormElementsModule } from './bs-form-elements.module';
import { NgZorroElementsModule } from './ng-zorro-elements.module';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BsTableComponent } from '../components/table/bs-table.component';
import { LocalizePipe } from '../pipes/localize.pipe';
import { DocumentService } from 'src/app/core/services/document.service';
import { BsFormComponent } from '../components/form/bs-form.component';
import { RedirectGuard } from 'src/app/core/guards/redirect.guard';
import { ChartsModule } from 'ng2-charts';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BsFormElementsModule,
        NgZorroElementsModule,
        ReactiveFormsModule,
        ChartsModule
    ],
    exports: [
        CommonModule,
        MomentPipe,
        FilterPipe,
        BusyDirective,
        BsFormElementsModule,
        NgZorroElementsModule,
        BsTableComponent,
        LocalizePipe,
        BsFormComponent
    ],
    declarations: [
        MomentPipe,
        FilterPipe,
        BusyDirective,
        BsTableComponent,
        LocalizePipe,
        BsFormComponent,
    ],
    providers: [
        SharedService
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                PendingChangesGuard,
                NzMessageService,
                DocumentService,
                RedirectGuard
            ]
        };
    }
}
