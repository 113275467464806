import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "../services/app-session.service";

@Injectable()
export class RedirectGuard implements CanActivate  {
  constructor(private router: Router,
    private permissionChecker: PermissionCheckerService,
    private sessionService: AppSessionService) {}

  canActivate() : boolean {
    if (!this.sessionService.user) {
        this.router.navigate(['/authentication/login']);
    }else if (this.permissionChecker.isGranted("Pages.Users")) {
        return true;
    }
    else if (this.permissionChecker.isGranted("Pages.Drivers")) {
        this.router.navigate(['app/drivers']);
    }
    else if (this.permissionChecker.isGranted("Pages.Vehicles")) {
        this.router.navigate(['app/vehicles']);
    }
    else if (this.permissionChecker.isGranted("Pages.MobileDevices")) {
        this.router.navigate(['app/mobile-devices']);
    }
    else if (this.permissionChecker.isGranted("Pages.TrackingData")) {
        this.router.navigate(['app/tracking-data']);
    }
  }
}