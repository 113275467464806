import { BaseFormElement } from '../base/base-form-element';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { forwardRef, Component, Optional, Host, SkipSelf, Input, Injector } from '@angular/core';
import { KeyValueModel } from 'src/app/shared/models/base/key-value.model';

@Component({
    selector: 'bs-radio',
    templateUrl: 'bs-radio.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsRadioComponent),
            multi: true
        }
    ]
})

export class BsRadioComponent extends BaseFormElement {
    @Input() items: KeyValueModel[];
    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //validate
    validate() { }
}