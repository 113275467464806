import { Component, Optional, Host, SkipSelf, Input, forwardRef, Injector } from '@angular/core';
import { BaseFormElement } from '../base/base-form-element';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'bs-number',
    templateUrl: 'bs-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsNumberComponent),
            multi: true
        }
    ]
})

export class BsNumberComponent extends BaseFormElement {
    @Input() min: number = Infinity;
    @Input() max: number = Infinity;
    @Input() step: number = 1;
    @Input() precision: number = undefined;
    @Input() formatter: any = (value: number) => value;
    @Input() parser: any = (value: string) => value;

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector) {
        super(controlContainer, injector);
    }
}