<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-time-picker [(ngModel)]="value" (ngModelChange)="onTimeChange($event)" [nzDisabled]="disabled"
            [nzAllowEmpty]="allowEmpty" [nzClearText]="clearText" [nzDisabledHours]="disabledHours"
            [nzDisabledMinutes]="disabledMinutes" [nzDisabledSeconds]="disabledSeconds" [nzFormat]="format"
            [nzHourStep]="hourStep" [nzMinuteStep]="minuteStep" [nzSecondStep]="secondStep"
            [nzPlaceHolder]="placeholder" [nzUse12Hours]="user12Hours" [nzSuffixIcon]="suffixIcon">
        </nz-time-picker>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>