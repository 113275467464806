import { NgModule } from '@angular/core';
import { BsTextboxComponent } from '../components/form-elements/bs-textbox/bs-textbox.component';
import { BsPasswordtComponent } from '../components/form-elements/bs-password/bs-password.component';
import { NgZorroElementsModule } from './ng-zorro-elements.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsEmailComponent } from '../components/form-elements/bs-email/bs-email.component';
import { BsCheckboxComponent } from '../components/form-elements/bs-checkbox/bs-checkbox.component';
import { BsRadioComponent } from '../components/form-elements/bs-radio/bs-radio.component';
import { BsNumberComponent } from '../components/form-elements/bs-number/bs-number.component';
import { BsSelectComponent } from '../components/form-elements/bs-select/bs-select.component';
import { BsTextareaComponent } from '../components/form-elements/bs-textarea/bs-textarea.component';
import { BsDatePickerComponent } from '../components/form-elements/bs-date-picker/bs-date-picker.component';
import { BsRangePickerComponent } from '../components/form-elements/bs-range-picker/bs-range-picker.component';
import { BsTimePickerComponent } from '../components/form-elements/bs-time-picker/bs-time-picker.component';
import { BsFileUploadComponent } from '../components/form-elements/bs-file-upload/bs-file-upload.component';
import { BsAutocompleteComponent } from '../components/form-elements/bs-autocomplete/bs-autocomplete.component';
import { BsSliderComponent } from '../components/form-elements/bs-slider/bs-slider.component';
import { BsSwitchComponent } from '../components/form-elements/bs-switch/bs-switch.component';


@NgModule({
  imports: [
    NgZorroElementsModule,
    FormsModule,
    CommonModule
  ],
  exports: [
    NgZorroElementsModule,
    BsTextboxComponent,
    BsEmailComponent,
    BsPasswordtComponent,
    BsCheckboxComponent,
    BsRadioComponent,
    BsNumberComponent,
    BsSelectComponent,
    BsTextareaComponent,
    BsDatePickerComponent,
    BsRangePickerComponent,
    BsTimePickerComponent,
    BsFileUploadComponent,
    BsAutocompleteComponent,
    BsSliderComponent,
    BsSwitchComponent
  ],
  declarations: [
    BsTextboxComponent,
    BsEmailComponent,
    BsPasswordtComponent,
    BsCheckboxComponent,
    BsRadioComponent,
    BsNumberComponent,
    BsSelectComponent,
    BsTextareaComponent,
    BsDatePickerComponent,
    BsRangePickerComponent,
    BsTimePickerComponent,
    BsFileUploadComponent,
    BsAutocompleteComponent,
    BsSliderComponent,
    BsSwitchComponent
  ],
  providers: [],
})
export class BsFormElementsModule { }
