import { Component, Injector, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '../base/app-component-base';
import { BsTableFilter } from '../../models/table/bs-table-fiter.model';
import { BsTableModel } from '../../models/table/bs-table.model';
import { BsTableActionEmit } from '../../models/table/bs-table-action.model';
import { BsTableHeaderAction, BsTableHeaderActionEmit } from '../../models/table/bs-table-header-action.model';
import { MomentPipe } from '../../pipes/moment.pipe';
import { DecimalPipe } from '@angular/common';
import { BsTableColumn } from '../../models/table/bs-table-column.model';
import { RouteEventsService } from 'src/app/core/services/router-event.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzTableFilterValue, NzTableSortOrder, NzTableQueryParams } from 'ng-zorro-antd/table';
import { ValueLabelDto } from 'src/app/core/http/service-proxies/service-proxies';
import * as moment from 'moment';

@Component({
  selector: 'bs-table',
  templateUrl: 'bs-table.component.html',
  styleUrls: ['bs-table.component.scss'],
  providers: [MomentPipe, DecimalPipe]
})

export class BsTableComponent extends AppComponentBase implements OnChanges {
  @Input() model: BsTableModel;
  @Input() loading: boolean;
  @Input() serverLoading: boolean;
  @Input() data: any;
  @Input() total: number;
  @Input() showTable = true;

  @Output() onAction: EventEmitter<BsTableActionEmit> = new EventEmitter();
  @Output() onHeaderAction: EventEmitter<BsTableHeaderActionEmit> = new EventEmitter();
  @Output() onDataRequest: EventEmitter<NzTableQueryParams> = new EventEmitter();
  @Output() onRowClick: EventEmitter<BsTableActionEmit> = new EventEmitter();
  @Output() onFilterClick: EventEmitter<any> = new EventEmitter();

  displayedData: any;
  filterData: any = {};
  setOfCheckedId = new Set<number>();
  expandSet = new Set<number>();
  checked: boolean = false;
  indeterminate: boolean = false;
  sortParams: Array<{
    key: string;
    value: NzTableSortOrder;
  }> = new Array();

  filterParams: Array<{
    key: string;
    value: NzTableFilterValue;
  }> = [];
  operators;
  confirmModal?: NzModalRef;
  selectThreeStateItems: ValueLabelDto[];
  onSelectFilterChange: any;

  constructor(injector: Injector,
    private momentPipe: MomentPipe,
    private decimalPipe: DecimalPipe,
    private modal: NzModalService,
    private routeEventsService: RouteEventsService) {
    super(injector);

    this.operators = {
      '==': (a, b) => { return a == b },
      '===': (a, b) => { return a === b },
      '!=': (a, b) => { return a != b },
      '!==': (a, b) => { return a !== b },
      '>': (a, b) => { return a > b },
      '<': (a, b) => { return a < b },
      '>=': (a, b) => { return a >= b },
      '<=': (a, b) => { return a <= b },
    };

    this.selectThreeStateItems = [
      new ValueLabelDto({ value: 0, label: this.l("AllRecords") }),
      new ValueLabelDto({ value: 1, label: this.l("ActiveRecords") }),
      new ValueLabelDto({ value: 2, label: this.l("InactiveRecords") })
    ];
  }

  /**
   *  Method for adding css class to column
   *
   * @param {BsTableColumn} column
   * @memberof BsTableComponent
   */
  addClassToColumn(column: BsTableColumn) {
    let res = '';
    if (this.model.options.onRowClick) res = 'clickable ';
    if (column.hideXs) return res + "xs-none";
    if (column.hideSm) return res + "sm-none";
    if (column.hideMd) return res + "md-none";
    if (column.hideLg) return res + "lg-none";
    return res;
  }

  /**
   * Private method for check if condition is matched
   *
   * @private
   * @param {*} condition
   * @returns
   * @memberof BsTableComponent
   */
  private checkConditions(object: any, condition: any) {
    let result = false;
    if (condition.logical === undefined) condition.logical = "OR";
    let allTrue = [];
    condition.conditions.every((element) => {
      let check = this.operators[element.operator](object[element.prop], element.value);
      if (check && condition.logical === "OR") {
        result = true;
        return false;
      } else if (check) {
        allTrue.push(check);
      }
      return true;
    });
    if (condition.logical === "AND" && condition.conditions.length == allTrue.length) {
      result = true;
    }
    return result;
  }

  /**
   * Method for doing search on client
   *
   * @memberof BsTableComponent
   */
  clientSearch() {
    let data = this.data;

    this.model.filters.forEach(filter => {
      if (this.filterData[filter.name] == null && filter.type === "select-three-state") {
        data = data.filter(it => it[filter.lookUpColumns[0]] == true || it[filter.lookUpColumns[0]] == false);
      }
      if (this.filterData[filter.name] != null && this.filterData[filter.name] != "") {

        sessionStorage.setItem(filter.name, JSON.stringify(this.filterData[filter.name]));

        //filter type of string
        if (filter.type == null || filter.type === "string") {
          if (filter.multipleFilterOperator === "AND") {
            filter.lookUpColumns.forEach(column => {
              data = data.filter(it => it[column].toLowerCase().includes(this.filterData[filter.name].toLowerCase()));
            });
          }

          if (filter.multipleFilterOperator === "OR" || filter.multipleFilterOperator == null) {
            let x = [];
            filter.lookUpColumns.forEach(column => {
              x = x.concat(data.filter(it => it[column].toLowerCase().includes(this.filterData[filter.name].toLowerCase())));
            });
            data = [...new Set(x)];
          }
        }

        //boolean or select filter
        else if (filter.type === "boolean" || filter.type === 'select') {
          data = data.filter(it => it[filter.lookUpColumns[0]] == this.filterData[filter.name]);
        }

        //select-three-state
        else if (filter.type === "select-three-state") {
          if (this.filterData[filter.name] == 1) data = data.filter(it => it[filter.lookUpColumns[0]] == true);
          if (this.filterData[filter.name] == 2) data = data.filter(it => it[filter.lookUpColumns[0]] == false);
        }

        //numeric
        else if (filter.type === 'number') {
          if (filter.numericCompare === '<') {
            data = data.filter(it => it[filter.lookUpColumns[0]] < this.filterData[filter.name]);
          } else if (filter.numericCompare === '>') {
            data = data.filter(it => it[filter.lookUpColumns[0]] > this.filterData[filter.name]);
          } else if (filter.numericCompare === '>=') {
            data = data.filter(it => it[filter.lookUpColumns[0]] >= this.filterData[filter.name]);
          } else if (filter.numericCompare === '<=') {
            data = data.filter(it => it[filter.lookUpColumns[0]] <= this.filterData[filter.name]);
          } else {
            data = data.filter(it => it[filter.lookUpColumns[0]] == this.filterData[filter.name]);
          }
        }

        //range filter
        else if ((filter.type === 'range' || filter.type === 'rangedatetime') && this.filterData[filter.name].length == 2) {
          data = data.filter(it => it[filter.lookUpColumns[0]] >= this.filterData[filter.name][0]
            && it[filter.lookUpColumns[0]] <= this.filterData[filter.name][1]);
        }
      }
    });

    this.displayedData = [...data]
  }

  /**
   * Document this
   *
   * @param {*} value
   * @param {*} disableCondition
   * @memberof BsTableComponent
   */
  disableAction(object: any, condition: any) {
    let disable = false;
    if (condition != null) {
      disable = this.checkConditions(object, condition);
    }
    return disable;
  }

  /**
   * Emit action to parent component
   *
   * @param {string} action
   * @param {*} item
   * @memberof BsTableComponent
   */
  emitAction(action: string, item: any, modalConfirmTitle?: string, modalConfirmContent?: string) {
    if (modalConfirmTitle != undefined) {
      this.confirmModal = this.modal.confirm({
        nzTitle: modalConfirmTitle,
        nzContent: modalConfirmContent,
        nzOnOk: () => {
          this.onAction.emit(new BsTableActionEmit({ action: action, item: item }));
        }
      });
    } else {
      this.onAction.emit(new BsTableActionEmit({ action: action, item: item }));
    }
  }

  /**
   * Method for emit row click event handler
   *
   * @param {*} item
   * @memberof BsTableComponent
   */
  emitRowClick(item: any) {
    this.onRowClick.emit(item);
  }

  /**
   * Emit header action parent component
   *
   * @param {BsTableHeaderAction} action
   * @memberof BsTableComponent
   */
  emitHeaderAction(action: BsTableHeaderAction) {
    this.onHeaderAction.emit(new BsTableHeaderActionEmit({ action: action.name, selectedItems: action.enabledIfSelectedItems ? this.setOfCheckedId : null, disabled: action.disabled }))
  }

  /**
   * Format data base on type
   *
   * @param {*} value
   * @param {string} type
   * @returns
   * @memberof BsTableComponent
   */
  formatData(value: any, type: string, suffixText: string, prefixText: string) {
    let formated: string;
    if (type === "datetime") {
      if (moment.locale() === 'en') {
        formated = moment(value).format("DD/MM/YYYY hh:mm A");
      } else if (moment.locale() === 'de') {
        formated = this.momentPipe.transform(value, 'L') + ' ' + this.momentPipe.transform(value, 'LT');
      }
    } else if (type === 'datetimesec') {
      formated = this.momentPipe.transform(value, 'L') + ' ' + this.momentPipe.transform(value, 'LTS');
    } else if (type === 'datetimesecmilis') {
      formated = this.momentPipe.transform(value, 'L') + ' ' + this.momentPipe.transform(value, 'LTS.SSS');
    } else if (type === 'date') {
      formated = this.momentPipe.transform(value, 'L');
    } else if (type === 'boolean') {
      formated = value ? "&#10003;" : '';
    } else if (type === 'decimal') {
      formated = this.decimalPipe.transform(value, '1.2-2');
    } else {
      formated = value;
    }
    if (value != null) {
      return prefixText + " " + formated + " " + suffixText;
    }
    return null;
  }

  /**
   * Hide action if condition is satifsied
   *
   * @param {*} value
   * @param {*} disableCondition
   * @memberof BsTableComponent
   */
  hideAction(object: any, condition: any) {
    let hide = false;
    if (condition != null) {
      hide = this.checkConditions(object, condition);
    }
    return hide;
  }

  /**
   * On all checked event handler
   *
   * @param {boolean} checked
   * @memberof BsTableComponent
   */
  onAllChecked(checked: boolean): void {
    this.displayedData.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  /**
   * Expand change event handler
   *
   * @param {number} id
   * @param {boolean} checked
   * @memberof BsTableComponent
   */
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  /**
   * Item checked event handler
   *
   * @param {number} id
   * @param {boolean} checked
   * @memberof BsTableComponent
   */
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  /**
   * onQueryParamsChange event handler
   *
   * @param {NzTableQueryParams} params
   * @memberof BsTableComponent
   */
  onQueryParamsChange(params: NzTableQueryParams) {
    this.sortParams = params.sort;
    params.filter = this.filterParams;
    this.onDataRequest.emit(params);
  }

  /**
   * Method for preparing data for server search
   *
   * @memberof BsTableComponent
   */
  prepareForServerSearch() {
    if (this.sortParams !== undefined && this.sortParams.length !== 0) {
      let keyExists = this.sortParams.find(x => x.key == "search");
      let isSensorComponent = this.sortParams.find(x => x.key == "dateOfSavingInMobileDb");
      if (!keyExists && isSensorComponent) { this.sortParams.push({ key: "search", value: "searchBtnPresed" }) };
    } else {
      this.sortParams.push({
        key: "dateOfSavingInMobileDb",
        value: "descend"
      });
    }
    this.filterParams = this.filterData;
    this.onQueryParamsChange(
      {
        pageIndex: this.model.options.pageIndex,
        pageSize: this.model.options.pageSize,
        sort: this.sortParams,
        filter: this.filterData
      });
  }

  /**
   * Page changed event handler
   *
   * @param {number} page
   * @memberof BsTableComponent
   */
  pageChanged(page: number) {

  }

  /**
   * Row highlight action
   *
   * @param {*} object
   * @param {*} condition
   * @returns
   * @memberof BsTableComponent
   */
  rowHighlight(object: any, condition: any) {
    if (condition != null) {
      for (let element of condition) {
        if (this.checkConditions(object, element)) {
          return {
            'background-color': element.backgroundColor,
            'color': element.color
          }
        }
      }
    }
    return null;
  }

  /**
   * Refresh checked status in header
   *
   * @memberof BsTableComponent
   */
  refreshCheckedStatus(): void {
    this.checked = this.displayedData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = this.displayedData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  /** Range changed event handler */
  rangeChanged(event: Date[], filter: any) {
    event[0] = new Date(event[0].getFullYear(), event[0].getMonth(), event[0].getDate(), 0, 0, 0);
    event[1] = new Date(event[1].getFullYear(), event[1].getMonth(), event[1].getDate(), 23, 59, 59);
  }

  /**
  * Method for restoring fitler if needed
  *
  * @memberof BsTableComponent
  */
  restoreFilter() {
    if (this.model.options.PreviousRoutesSaveFilter != null) {
      let previousRoute = this.routeEventsService.previousRoutePath.value;
      let check = this.model.options.PreviousRoutesSaveFilter.filter(it => previousRoute.includes(it));
      if (check.length > 0) {
        this.model.filters.forEach(filter => {
          if (sessionStorage.getItem(filter.name) != null) {
            this.filterData[filter.name] = JSON.parse(sessionStorage.getItem(filter.name));
          }
        });
      } else {
        this.reset();
      }
    } else {
      // this.reset();
    }
  }

  /**
   * Reset all filters
   *
   * @memberof BsTableComponent
   */
  reset() {
    this.model.filters.forEach(filter => {
      this.filterData[filter.name] = filter.defaultValue == null ? null : filter.defaultValue;
      sessionStorage.removeItem(filter.name);
    });
    this.search();
  }

  /**
   * Do search based on fitlers
   *
   * @memberof BsTableComponent
   */
  search() {
    if (this.model.options.frontPagination == true) {
      this.clientSearch();
    } else {
      this.prepareForServerSearch();
    }
  }

  /**
   * Update check event handler
   *
   * @param {number} id
   * @param {boolean} checked
   * @memberof BsTableComponent
   */
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  /**
   * OnChanges lifecyle event
   *
   * @param {*} changes
   * @memberof BsTableComponent
   */
  ngOnChanges(changes: any) {
    if (changes.data != null && changes.data.currentValue != null) {
      this.displayedData = changes.data.currentValue;
      // this.restoreFilter();

      // this.model.filters.forEach((filter) => {
      //     if (filter.defaultValue != null) this.filterData[filter.name] = filter.defaultValue;
      // });

      if (this.model.options.frontPagination) this.search();
    }
  }

  onSelectChnage(event: any, filter: any) {
    if (filter.name === "mobileDeviceId") {
      this.onFilterClick.emit(event);
    }
  }

}
