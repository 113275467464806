import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    //method for setting date to min hours
    setDateToMinHours(date: Date): moment.Moment {
        return moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    }

    //method for setting date to min hours
    setDateToMaxHours(date: Date): moment.Moment {
        return moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
    }

    isIMEIValid(input: any): boolean {​​​​​​​​

      if (!input || input.length !== 15) {​​​​​​​​
        return false;
      }​​​​​​​​

      const posIMEI = [15];

      for (let inloop = 0; inloop < 15; inloop++) {​​​​​​​​
        const chacracter = input.charAt(inloop);
          if (chacracter !== undefined) {​​​​​​​​
            posIMEI[inloop] = +chacracter;
          }​​​​​​​​
          if (inloop % 2 !== 0) {​​​​​​​​
            posIMEI[inloop] = posIMEI[inloop] * 2;
          }​​​​​​​​
          while (posIMEI[inloop] > 9) {​​​​​​​​
            posIMEI[inloop] = +Math.floor(
              (posIMEI[inloop] % 10) + posIMEI[inloop] / 10
            );
          }​​​​​​​​
          }​​​​​​​​
          const totalVal = posIMEI.reduce((a, b) =>a + b, 0);
          return 0 === totalVal % 10;
      }
      
    isVINValid(input: any): boolean {
      let re = new RegExp('^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$');
      if (input.match(re)) {
        return true;
      }
      return false;
    }
}
