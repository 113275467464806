export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'UlriciusHope'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    /**
     * Need because of this
     * https://github.com/NG-ZORRO/ng-zorro-antd/issues/5182
     *
     * @static
     * @memberof AppConsts
     */
    static readonly regional = [
        {
            lng: 'en', value: {
                dateFormat: 'dd/MM/yyyy',
                dateTimeFormat: 'dd/MM/yyyy HH:mm:ss'
            }
        },
        {
            lng: 'hr', value: {
                dateFormat: 'dd.MM.yyyy',
                dateTimeFormat: 'dd.MM.yyyy HH:mm:ss'
            }
        }
    ]
}
