<nz-form-item [ngClass]="{'ant-form-item-has-error':hasErrors}">
    <nz-form-label [nzRequired]="isRequired">{{label}}
        <i *ngIf="info != null" nz-icon nz-tooltip nzTooltipTitle="{{info}}" nzType="question-circle"
            nzTheme="outline"></i>
    </nz-form-label>
    <nz-form-control>
        <nz-input-number [nzPlaceHolder]="placeholder != null ? placeholder : ''" [nzDisabled]="disabled"
            [(ngModel)]="value" [nzMin]="min" [nzMax]="max" [nzStep]="step" [nzPrecision]="precision"
            [nzFormatter]="formatter" [nzParser]="parser" [nzSize]="size">
        </nz-input-number>
        <div class="ant-form-item-explain" *ngIf="errorRequired">{{requiredErrorMessage}} </div>
    </nz-form-control>
</nz-form-item>