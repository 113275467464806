import { BaseFormElement } from '../base/base-form-element';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { Component, forwardRef, Optional, Host, SkipSelf, Input, Injector } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'bs-time-picker',
    templateUrl: 'bs-time-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BsTimePickerComponent),
            multi: true
        }
    ]
})
export class BsTimePickerComponent extends BaseFormElement {
    @Input() allowEmpty: boolean = true;
    @Input() clearText: string = this.l("Clear");
    @Input() disabledHours: any;
    @Input() disabledMinutes: any;
    @Input() disabledSeconds: any;
    @Input() format: string = 'HH:mm:ss';
    @Input() hourStep: number = 1;
    @Input() minuteStep: number = 1;
    @Input() secondStep: number = 1;
    @Input() user12Hours: boolean = false;
    @Input() suffixIcon: string = '';

    constructor(
        @Optional() @Host() @SkipSelf()
        controlContainer: ControlContainer,
        injector: Injector
    ) {
        super(controlContainer, injector);
    }

    //on time change
    onTimeChange(result: Date): void {
        let date = moment(result);
        this.onChange(date);
        this.onTouch(date);
    }

    //method to set the value to the native form control
    writeValue(value: moment.Moment): void {
        if (value != null) {
            this.value = value.toDate();
        }
    }
}